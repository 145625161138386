import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { Root, CopilotWidget } from "@openchatai/copilot-widget";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import Save from "@mui/icons-material/Save";
import Print from "@mui/icons-material/Print";
import Share from "@mui/icons-material/Share";



import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-15 pb-12 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url('../demo/images/screen-4.png')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-20 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-black font-bold text-5xl">Welcome</h1>
                  <p className="mt-1 text-2xl text-white-300 font-bold">
                    your business ally we are a technology services company.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-10 bg-gray-300 -mt-10">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                    <img
                      alt="..."
                      src="https://img.freepik.com/foto-gratis/concepto-informacion-linea-directa-centro-llamadas_53876-125154.jpg?size=626&ext=jpg&ga=GA1.1.1803636316.1701561600&semt=ais"
                      className="w-full align-middle rounded-t-lg"
                    />
                    <blockquote className="relative p-8 mb-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block"
                        style={{
                          height: "95px",
                          top: "-94px",
                        }}
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-green-600 fill-current"
                        ></polygon>
                      </svg>

                      <h6 className="text-xl font-semibold">Support Staff</h6>
                      <p className="mt-2 mb-4 text-gray-600">
                        Remote Technical Support and Online.
                      </p>
                      <Link to="https://api.whatsapp.com/send?phone=16469966774&text=Hola vengo del sitio web Dimacit.com" target="_blank">
                        <Button color="green" className="text-xl text-white">
                        <FontAwesomeIcon icon={faWhatsapp} /> +16469966774
                        </Button>
                      </Link>
                    </blockquote>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                    <img
                      alt="..."
                      src="https://img.freepik.com/foto-gratis/pos-terminal-tienda-listo-trabajar_1153-3610.jpg"
                      className="w-full align-middle rounded-t-lg"
                    />
                    <blockquote className="relative p-8 mb-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block"
                        style={{
                          height: "95px",
                          top: "-94px",
                        }}
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-blue-600 fill-current"
                        ></polygon>
                      </svg>
                      <h4 className="text-xl font-bold text-black">
                        <h6 className="text-xl font-semibold">
                          Equipment Sale
                        </h6>
                      </h4>
                      <p className="mt-2 mb-4 text-gray-600">
                        Advice on technological acquisition.
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>

              

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  {/* <div className="px-4 py-5 flex-auto"> */}
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                    <img
                      alt="..."
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ8zHnI01h-Blit0xKeO4LGrRTuLJD6x-fTg&usqp=CAU"
                      className="w-full align-middle rounded-t-lg"
                    />
                    <blockquote className="relative p-8 mb-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block"
                        style={{
                          height: "95px",
                          top: "-94px",
                        }}
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-pink-600 fill-current"
                        ></polygon>
                      </svg>
                      <h4 className="text-xl font-bold text-black">
                        Sale of Accesories
                      </h4>
                      <p className="text-md font-light mt-2 text-black">
                        Supplies for technological equipment.
                      </p>
                    </blockquote>

                    <h6 className="text-xl font-semibold"></h6>
                    <p className="mt-2 mb-4 text-gray-600"></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-3">
              <div className="w-full md:w-6/12 px-16 mr-auto ml-auto">
                <h3 className="text-4xl mb-2 font-semibold leading-normal text-center">
                  WE ARE YOUR BEST BUSINESS PARTNER
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-center">
                  “We are a great tool for many businesses in New York City that
                  look to us when problems arise in their daily operations. We
                  always seek to improve our predictive service levels that
                  allow us to anticipate the problems that your business may
                  have..”
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://img.freepik.com/premium-photo/security-camera-urban-video_109643-54.jpg"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px",
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-pink-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-black">
                      Maintenance of Security Systems
                    </h4>
                    <p className="text-md font-light mt-2 text-black">
                      The Arctic Ocean freezes every winter and much of the
                      sea-ice then thaws every summer, and that process will
                      continue whatever happens.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://img.freepik.com/premium-photo/wireless-router-black-background-wifi-router-internet-connection-generative-ai_574777-1048.jpg"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-bold uppercase">
                    Access Points and Routers
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Professional installation of your wifi network.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Carefully selected components
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Better performance on your network
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Better signal in all sectors of your business
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-10 pb-28">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 ">
                <h2 className="text-4xl font-semibold text-transform: uppercase">
                  We are business partners of Aldelo Pos System
                </h2>

                <img
                  alt="..."
                  src="https://www.aldelo.com/uploads/1/0/1/3/101368870/editor/masa-aldelo-express-pos-customer-display_1.png"
                  className="shadow-lg  max-w-full mx-auto"
                  style={{ maxWidth: "2520px" }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt=""
                    src="https://img.freepik.com/psd-gratis/representacion-3d-fondo-dispositivo_23-2150571835.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "157px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">
                      Updating Technological Equipment
                    </h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      We update with the best parts and brands.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt=""
                    src="https://img.freepik.com/free-photo/retinal-biometrics-technology-with-man-s-eye-digital-remix_53876-108518.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "157px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Biometric Watches</h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      Biometric employee time attendance machines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src="https://img.freepik.com/premium-photo/server-rack-switch-ports-with-connected-cables-generative-ai_1162196-11213.jpg"
                    className="shadow-lg rounded-lg max-w-full mx-auto"
                    style={{ maxWidth: "197px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Switches and Racks</h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      Components you need to complete your communications
                      network.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src="https://img.freepik.com/premium-photo/person-using-touch-screen-check-store_873925-51548.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "197px" }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">
                      Touch Screens for POS Systems
                    </h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                      We work with the best brands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-1 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  POS SYSTEM
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  Streamline your sales process and give your customers a unique
                  and fast purchasing experience. Goodbye long lines during peak
                  hours....
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="flex flex-wrap mt-10 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-md bg-white inline-flex items-center justify-center">
                  <img
                    alt=""
                    src="https://img.freepik.com/fotos-premium/caja-registradora-negra-pantalla-monitor-computadora-blanco-bar_189959-4889.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "257px" }}
                  />
                </div>
                <h6 className="text-xl mt-4 font-semibold text-white">
                  Manage your entire business in one place.
                </h6>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <img
                    alt=""
                    src="https://img.freepik.com/foto-gratis/cerrar-panadero-panaderia_23-2149233716.jpg?size=626&ext=jpg&ga=GA1.1.117944100.1709510400&semt=ais"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "237px" }}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Custom Floor Plans
                </h5>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <img
                    alt=""
                    src="https://blog-assets.lightspeedhq.com/img/2020/08/87e0f135-friends-in-cafe.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "237px" }}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  You use your tablet, laptop or cell phone as a point of sale
                  for both Android and iOS
                </h5>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <img
                    alt=""
                    src="https://img.freepik.com/free-photo/front-view-woman-working-bakery_23-2150273188.jpg"
                    className="shadow-lg rounded-md max-w-full mx-auto"
                    style={{ maxWidth: "237px" }}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Manage sales terminals in one place
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-20 px-20 lg:pt-0 bg-gray-900">
          <div className=" flex flex-wrap container  justify-left mx-5  px-5">
            <Card
              color="orange"
              variant="gradient"
              className="w-full max-w-[16rem] p-8  mr-6"
            >
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 mb-8 rounded-none border-b border-white/11 pb-8 text-center"
              >
                <Typography
                  variant="h3"
                  color="white"
                  className="font-bold uppercase"
                >
                  Plan Standard
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  className="mt-6 flex justify-center gap-1  font-normal"
                >
                  (PHONE/REMOTE SUPPORT)
                  <br />
                  Just Service/travel fee are include
                </Typography>
              </CardHeader>
              <CardBody className="p-0">
                <ul className="flex flex-col gap-4">
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-amber bg-amber-500 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Support charge type: Per Incident charge
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-amber bg-amber-500 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Remote Technical Support Telephone: Per Incident Charge
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-amber bg-amber-500 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Onsite Technical Support: Per Incident Charge $ 180
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-amber bg-amber-500 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Incident Support Coverage: 9:00 am to 5:00 pm Not Cover
                      Holidays
                    </Typography>
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="mt-12 p-0">
                <Button
                  size="lg"
                  color="white"
                  className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                  ripple={false}
                  fullWidth={true}
                >
                  Acquire
                </Button>
              </CardFooter>
            </Card>
            <Card
              color="deep-orange"
              variant="gradient"
              className="w-full max-w-[16rem] p-8 mr-6"
            >
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 mb-8 rounded-none border-b border-white/11 pb-8 text-center"
              >
                <Typography
                  variant="h3"
                  color="white"
                  className="font-bold uppercase"
                >
                  Plan Silver
                </Typography>

                <br />
                <Typography className="text-white font-bold">
                  ANNUAL SUPPORT BASIC
                </Typography>
              </CardHeader>
              <CardBody className="p-0">
                <ul className="flex flex-col gap-4">
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-orange-600 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Support Charge Type: Help Desk
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-orange-600 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Remote Technical Support Telephone:
                      <br />
                      Unlimited
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-orange-600 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Onsite technical Support:
                      <br />3 Visits per year (Incident Charge $ 150)
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-orange-600 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Incident Support Coverage:
                      <br />
                      9:00am to 6:00pm M-F
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-orange-600 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Emergency Support Coverage:
                      <br />
                      Per Incident Charge
                    </Typography>
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="mt-12 p-0">
                <Button
                  size="lg"
                  color="white"
                  className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                  ripple={false}
                  fullWidth={true}
                >
                  Acquire
                </Button>
              </CardFooter>
            </Card>
            <Card
              color="lime"
              variant="gradient"
              className="w-full max-w-[16rem] p-8 mr-6"
            >
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 mb-8 rounded-none border-b border-white/11 pb-8 text-center"
              >
                <Typography
                  variant="h3"
                  color="white"
                  className="font-bold uppercase"
                >
                  Plan Gold
                </Typography>

                <br />

                <Typography className="text-grey font-bold">
                  ANNUAL SUPPORT BASIC+
                </Typography>
              </CardHeader>
              <CardBody className="p-0">
                <ul className="flex flex-col gap-4">
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white bg-white p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Support Charge Type:
                      <br />
                      Help Desk/travel Included
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-grey bg-white p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Remote Technical Support Telephone:
                      <br />
                      Unlimited
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-grey bg-white p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Onsite Technical Support:
                      <br />
                      3 Visits Per Year
                      <br />
                      (Incident Charge $ 135)
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-grey bg-white p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Incident Support Coverage:
                      <br />
                      9:00am to 6:00pm.M-F
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-grey bg-white p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Emergency Support Coverage:
                      <br />
                      Per Incident Charge
                    </Typography>
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="mt-12 p-0">
                <Button
                  size="lg"
                  color="white"
                  className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                  ripple={false}
                  fullWidth={true}
                >
                  Acquire
                </Button>
              </CardFooter>
            </Card>
            <Card
              color="light-green"
              variant="gradient"
              className="w-full max-w-[16rem] p-8"
            >
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 mb-8 rounded-none border-b border-white/11 pb-8 text-center"
              >
                <Typography
                  variant="h3"
                  color="white"
                  className="font-bold uppercase"
                >
                  Plan Diamond
                </Typography>
                <br />
                <Typography className="text-white font-bold">
                  ANNUAL SUPPORT PREMIUM
                </Typography>
              </CardHeader>
              <CardBody className="p-0">
                <ul className="flex flex-col gap-4">
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Support Charge Type:
                      <br />
                      Help Desk/travel Included
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Remote Technical Support Telephone:
                      <br />
                      Unlimited
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Onsite Technical Support:
                      <br />
                      Included
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Incident Support Coverage:
                      <br />
                      9:00am to 10:00pm
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      Emergency Support Coverage:
                      <br />
                      Included
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white-200 bg-amber-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="font-bold">
                      <ul className="list-disc">
                        <li>Loaner Unit Available</li>
                        <li>3-4 Hours Recover Up Time Guarantee</li>
                        <li>System Monitoring</li>
                        <li>Database Backup</li>
                      </ul>
                    </Typography>
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="mt-12 p-0">
                <Button
                  size="lg"
                  color="white"
                  className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                  ripple={false}
                  fullWidth={true}
                >
                  Acquire
                </Button>
              </CardFooter>
            </Card>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
