import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import privacyPolicy from "../assets/pdf/Privacy_Policy_Dimacit.pdf"; // Ruta corregida

export default function Footer() {
  return (
    <footer className="fixed relative bg-gray-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">DIMAC IT</h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Your business ally. We are a technology services company.
            </h5>
            <div className="mt-6"></div>
          </div>
          <div className="fixed bottom-0 right-0 mb-0 mr-0 z-50">
            <Link
              to="https://api.whatsapp.com/send?phone=16469966774&text=Hola vengo del sitio web Dimacit.com"
              target="_blank"
            >
              <Button color="green" className="text-xl text-white flex">
                <FontAwesomeIcon icon={faWhatsapp} /> +16469966774
              </Button>
            </Link>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              <a
                href={privacyPolicy} // Usamos la variable importada
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900"
              >
                Privacy Policy -
              </a>
              Copyright © {new Date().getFullYear()} DIMAC IT Design by{" "}
              <a
                href="https://www.dimacit.com"
                className="text-gray-600 hover:text-gray-900"
              >
                JuanG
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
